<template>
  <div class="current-opening" :class="{ 'current-opening--open': open }">
    <div class="current-opening__headline">
      <h3>{{ title }}</h3>
    </div>
    <div class="current-opening__excerpt" v-html="excerpt"></div>
    <transition
      name="expand"
      @enter="enter"
      @after-enter="afterEnter"
      @leave="leave"
    >
      <div v-if="open" class="current-opening__more" v-html="copy"></div>
    </transition>
    <div class="current-opening__actions">
      <button class="current-opening__action" @click="open = !open">
        <template v-if="open">
          {{ readLess }}
        </template>
        <template v-else>
          {{ readMore }}
        </template>
        <span class="current-opening__caret">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="18.594"
            height="11.418"
            viewBox="0 0 18.594 11.418"
          >
            <g
              id="Group_144"
              data-name="Group 144"
              transform="translate(9.297 2.121) rotate(45)"
            >
              <line
                id="Line_29"
                data-name="Line 29"
                y1="10.148"
                transform="translate(0 0)"
                fill="none"
                stroke="currentColor"
                stroke-linecap="round"
                stroke-width="3"
              />
              <line
                id="Line_30"
                data-name="Line 30"
                x2="10.148"
                transform="translate(0)"
                fill="none"
                stroke="currentColor"
                stroke-linecap="round"
                stroke-width="3"
              />
            </g>
          </svg>
        </span>
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CurrentOpening',
  props: {
    title: {
      type: String,
      required: true,
    },
    excerpt: {
      type: String,
      required: true,
    },
    copy: {
      type: String,
      required: true,
    },
    readMore: {
      type: String,
      default: 'Read More',
    },
    readLess: {
      type: String,
      default: 'Read Less',
    },
  },
  data() {
    return {
      open: false,
    };
  },
  methods: {
    enter(element) {
      const width = getComputedStyle(element).width;

      element.style.width = width;
      element.style.position = 'absolute';
      element.style.visibility = 'hidden';
      element.style.height = 'auto';

      const height = getComputedStyle(element).height;

      element.style.width = null;
      element.style.position = null;
      element.style.visibility = null;
      element.style.height = 0;

      // Force repaint to make sure the
      // animation is triggered correctly.
      getComputedStyle(element).height;

      // Trigger the animation.
      // We use `requestAnimationFrame` because we need
      // to make sure the browser has finished
      // painting after setting the `height`
      // to `0` in the line above.
      requestAnimationFrame(() => {
        element.style.height = height;
      });
    },
    afterEnter(element) {
      element.style.height = 'auto';
    },
    leave(element) {
      const height = getComputedStyle(element).height;

      element.style.height = height;

      // Force repaint to make sure the
      // animation is triggered correctly.
      getComputedStyle(element).height;

      requestAnimationFrame(() => {
        element.style.height = 0;
      });
    },
  },
};
</script>
