import Vue from 'vue';
import VueSplide from '@splidejs/vue-splide';
import Capabilities from './components/Capabilities.vue';
import CategoryList from './components/CategoryList.vue';
import CurrentOpening from './components/CurrentOpening.vue';
import Loader from './components/Loader.vue';
import MeetOurTeam from './components/MeetOurTeam.vue';

Vue.use(VueSplide);

Vue.component('capabilities', Capabilities);
Vue.component('category-list', CategoryList);
Vue.component('current-opening', CurrentOpening);
Vue.component('loader', Loader);
Vue.component('meet-our-team', MeetOurTeam);

new Vue({
  el: '#app',
  data: {
    menuOpen: false,
    scrollPosition: 0,
  },
  computed: {
    hasScrolled() {
      return this.scrollPosition >= 20;
    },
  },
  created() {
    window.addEventListener('scroll', this.handleScroll);
  },
  methods: {
    handleScroll() {
      this.scrollPosition = window.scrollY;
    },
  },
}).$mount();
