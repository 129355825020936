<template>
  <div class="category-list">
    <div v-if="categories" class="category-list__categories-container">
      <div class="category-list__categories">
        <div
          v-for="(category, index) in categories"
          :key="`category_${index}`"
          class="category-list__category"
        >
          <button
            type="button"
            class="category-list__category-button"
            :class="categoryClasses(category)"
            @click="activeCategorySlug = category.slug"
          >
            {{ category.title }}
          </button>
        </div>
      </div>
    </div>
    <div class="category-list__items-container">
      <ul v-if="mappedItems.length" class="category-list__items">
        <template v-for="(item, index) in mappedItems">
          <transition :key="`item_${index}`" name="fade">
            <li
              v-if="showItem(item)"
              :key="`item_${index}`"
              class="category-list__item category-item"
              :style="`background-image: url(${item.featuredImage[0].url})`"
            >
              <a v-if="item.url" class="category-item__link" :href="item.url">
                <div class="category-item__inner">
                  <div class="category-item__title">
                    <p>
                      {{ item.title }}
                    </p>
                  </div>
                </div>
              </a>
              <div v-else class="category-item__link category-item__link--none">
                <div class="category-item__inner">
                  <div class="category-item__title">
                    <p>
                      {{ item.title }}
                    </p>
                  </div>
                </div>
              </div>
            </li>
          </transition>
        </template>
      </ul>
      <div v-else-if="!loading">No items to display.</div>
    </div>
    <div v-if="loading" class="category-list__loading">
      <loader></loader>
    </div>
    <div class="category-list__actions">
      <button
        v-if="canLoadMore"
        class="button"
        type="button"
        :disabled="loading"
        @click="getItems"
      >
        <template v-if="loading"> Loading Items... </template>
        <template v-else>Load More</template>
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CategoryList',
  props: {
    categories: {
      type: Array,
      default: null,
    },
    itemCategoryKey: {
      type: String,
      default: null,
    },
    itemTagsKey: {
      type: String,
      required: true,
    },
    section: {
      type: String,
      required: true,
    },
    sections: {
      type: Array,
      required: true,
    },
    disableUrl: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      items: [],
      // limit set in created hook
      limit: null,
      total: null,
      loading: true,
      activeCategorySlug: this.categories?.[0].slug,
    };
  },
  computed: {
    offset() {
      return this.items.length;
    },
    canLoadMore() {
      return this.offset < this.total;
    },
    mappedItems() {
      return this.items.map((item) => ({
        ...item,
        url: this.disableUrl.includes(item.typeHandle) ? '' : item.url,
      }));
    },
    query() {
      const sectionString = this.sections
        .toString()
        .split(',')
        .map((section) => `"${section}"`)
        .join(',');
      const sections = `[${sectionString}]`;
      const subQuery = this.sections.reduce(
        (accumulator, section) => `
        ${accumulator}
        ... on ${this.section}_${section}_Entry {
          ${this.section}Tags {
            title
          }
          ${this.section}Category {
            slug
          }
          featuredImage {
            url
          }
        }
      `,
        ''
      );

      return `
        {
          entries(section: ${sections}, limit: ${this.limit}, offset: ${this.offset}) {
            typeHandle
            title
            url
            ${subQuery}
          }
          total: entryCount(section: ${sections})
        }
      `;
    },
  },
  created() {
    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams.has('category')) {
      this.activeCategorySlug = urlParams.get('category');
    }

    const screenWidth =
      window.innerWidth ||
      document.documentElement.clientWidth ||
      document.body.clientWidth;

    this.limit = screenWidth > 700 ? 20 : 4;

    this.getItems();

    this.limit = 4;
  },
  methods: {
    showItem(item) {
      if (this.activeCategorySlug === 'all' || this.categories === null) {
        return true;
      }

      if (
        this.activeCategorySlug === 'other' &&
        !item[this.itemCategoryKey].length
      ) {
        return true;
      }

      return item[this.itemCategoryKey].some(
        ({ slug }) => slug === this.activeCategorySlug
      );
    },
    categoryClasses(category) {
      return {
        'category-list__category-button--active':
          this.activeCategorySlug === category.slug,
      };
    },
    getItemTags(item) {
      return item[this.itemTagsKey].map((tag) => tag.title).join(' • ');
    },
    getItems() {
      this.loading = true;

      fetch(`${window.location.origin}/api`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ query: this.query }),
      })
        .then((response) => response.json())
        .then(({ data }) => {
          this.items = [...this.items, ...data.entries];
          this.total = data.total;
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>
