<template>
  <div
    class="container container--primary-bg container--y-spacing-xl container--x-spacing-md container--full-width"
  >
    <div class="capabilities">
      <div
        class="container container--primary-bg container--y-spacing-sm container--x-spacing-md container--standard-width"
      >
        <div class="capabilities__top">
          <div class="capabilities__headline">
            <h2>{{ headline }}</h2>
          </div>
          <div class="capabilities__copy" v-html="copy"></div>
        </div>
        <div class="capabilities__tabs-container">
          <div class="capabilities__tabs">
            <div
              v-for="(capability, index) in capabilities"
              :key="`c_tab_${capability.id}`"
              class="capabilities__tab"
            >
              <button
                type="button"
                class="capabilities__tab-button"
                :class="capabilityTabClasses(capability)"
                @click="setActiveCapability(capability, index)"
              >
                {{ capability.title }}
              </button>
            </div>
          </div>
        </div>
      </div>
      <div
        class="container container--secondary-bg container--y-spacing-xl container--x-spacing-md container--full-width"
        :style="{ backgroundColor: secondaryBackground }"
      >
        <div
          class="container container--secondary-bg container--y-spacing-none container--x-spacing-md container--standard-width"
        >
          <div class="capabilities__details" data-cartapus>
            <splide ref="splide" :options="splideOptions">
              <splide-slide
                v-for="capability in capabilities"
                :key="`c_info_${capability.id}`"
              >
                <div
                  class="capabilities__capability capability"
                  :class="capabilityClasses(capability)"
                >
                  <div class="capability__icon">
                    <img
                      :src="capability.image.url"
                      :alt="capability.image.altText"
                    />
                  </div>
                  <blockquote class="capability__copy">
                    <div
                      class="capability__quote"
                      v-html="capability.quote"
                    ></div>
                    <!-- <footer>
                      - <strong>{{ capability.authorName }}</strong
                      >, {{ capability.authorTitle }}
                    </footer> -->
                  </blockquote>
                </div>
              </splide-slide>
            </splide>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Capabilities',
  props: {
    headline: {
      type: String,
      required: true,
    },
    copy: {
      type: String,
      required: true,
    },
    capabilities: {
      type: Array,
      required: true,
    },
    secondaryBackground: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      activeCapabilitySlug: this.capabilities[0].slug,
      splideOptions: {
        arrows: false,
        pagination: false,
        autoHeight: true,
        type: 'fade',
      },
    };
  },
  methods: {
    capabilityTabClasses(capability) {
      return {
        'capabilities__tab-button--active':
          this.activeCapabilitySlug === capability.slug,
      };
    },
    capabilityClasses(capability) {
      return {
        'capability--active': this.activeCapabilitySlug === capability.slug,
      };
    },
    setActiveCapability(capability, index) {
      this.activeCapabilitySlug = capability.slug;
      this.$refs.splide.go(index);
    },
  },
};
</script>
