<template>
  <div class="team__members">
    <splide :options="splideOptions" @splide:moved="handleMoved">
      <splide-slide v-for="teamMember in teamMembers" :key="teamMember.id">
        <div
          class="team__member team-member"
          :class="teamMemberClasses(teamMember)"
          :style="`background-image: url(${teamMember.headshot.url})`"
        >
          <div class="team-member__inner">
            <div class="team-member__info-container">
              <div class="team-member__info">
                <div class="team-member__name">
                  <h3>
                    {{ teamMember.title }}
                  </h3>
                </div>
                <div class="team-member__position">
                  <p>
                    {{ teamMember.position }}
                  </p>
                </div>
              </div>
              <button
                class="team-member__close"
                type="button"
                @click="removeTeamMemberActive(teamMember.id)"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="19.799"
                  height="19.799"
                  viewBox="0 0 19.799 19.799"
                >
                  <g id="x-out" transform="translate(-1196.6 -53.6)">
                    <line
                      id="Line_7"
                      data-name="Line 7"
                      y2="22"
                      transform="translate(1214.278 55.722) rotate(45)"
                      fill="none"
                      stroke="currentColor"
                      stroke-linecap="square"
                      stroke-width="3"
                    />
                    <line
                      id="Line_8"
                      data-name="Line 8"
                      y1="22"
                      transform="translate(1214.278 71.278) rotate(135)"
                      fill="none"
                      stroke="currentColor"
                      stroke-linecap="square"
                      stroke-width="3"
                    />
                  </g>
                </svg>
              </button>
            </div>
            <div class="team-member__bio">
              <div class="team-member__bio-inner" v-html="teamMember.bio"></div>
            </div>
            <div v-if="!showBio(teamMember.id)" class="team-member__actions">
              <button
                type="button"
                class="link team-member__show-bio"
                @click="setTeamMemberActive(teamMember.id)"
              >
                {{ showBioButtonText }}
              </button>
            </div>
          </div>
        </div>
      </splide-slide>
      <template #controls>
        <div class="splide__arrows">
          <button
            v-show="enablePrevious"
            type="button"
            class="team__arrow team__arrow--previous splide__arrow splide__arrow--prev"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="43.074"
              height="69.722"
              viewBox="0 0 43.074 69.722"
            >
              <path
                d="M52.175,22.169,17.314-12.692H9.1l31.89,31.8,2.771,3.058-2.771,3.058h0L9.1,57.03h8.213Z"
                transform="translate(-9.101 12.692)"
              />
            </svg>
          </button>
          <button
            v-show="enableNext"
            type="button"
            class="team__arrow team__arrow--next splide__arrow splide__arrow--next"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="43.074"
              height="69.722"
              viewBox="0 0 43.074 69.722"
            >
              <path
                d="M52.175,22.169,17.314-12.692H9.1l31.89,31.8,2.771,3.058-2.771,3.058h0L9.1,57.03h8.213Z"
                transform="translate(-9.101 12.692)"
              />
            </svg>
          </button>
        </div>
      </template>
    </splide>
  </div>
</template>

<script>
export default {
  name: 'MeetOurTeam',
  props: {
    teamMembers: {
      type: Array,
      required: true,
    },
    showBioButtonText: {
      type: String,
      default: 'Read Bio',
    },
  },
  data() {
    return {
      splideOptions: {
        perPage: 1,
        fixedWidth: '80%',
        gap: '2rem',
        pagination: false,
        breakpoints: {
          767: {
            fixedWidth: '100%',
            gap: '1rem',
          },
        },
      },
      visableBios: [],
      currentIndex: 0,
    };
  },
  computed: {
    numberOfSlides() {
      return this.teamMembers.length;
    },
    enableNext() {
      return this.currentIndex < this.numberOfSlides - 1;
    },
    enablePrevious() {
      return this.currentIndex > 0;
    },
  },
  methods: {
    setTeamMemberActive(teamMemberId) {
      this.visableBios = [...this.visableBios, teamMemberId];
    },
    removeTeamMemberActive(teamMemberId) {
      this.visableBios = this.visableBios.filter(
        (visableId) => visableId !== teamMemberId
      );
    },
    showBio(teamMemberId) {
      return this.visableBios.includes(teamMemberId);
    },
    teamMemberClasses(teamMember) {
      return {
        'team-member--show-bio': this.showBio(teamMember.id),
      };
    },
    handleMoved(splide, index) {
      this.currentIndex = index;
    },
  },
};
</script>
